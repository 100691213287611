import React, {Suspense, useMemo, useState} from 'react'
import {Router} from '@reach/router'
import {ProviderLogin} from './ProviderLogin'
import {useSuspense, willUseSuspense} from 'sharecomponents/src/hooks/useSuspense'
import {useProviderInfo} from 'sharecomponents/src/hooks/useProviderInfo'
import {PatientList} from './PatientList'
import {getFetcher} from '@startlibs/utils'

const Identity = React.forwardRef(({children}, ref) => <React.Fragment>{children}<div ref={ref}/></React.Fragment>)

export const ProviderRoutes = () => {

  const [providerInfo] = useProviderInfo(useSuspense('/api/v2/provider'))
  const [jwt, setJwt] = useState()

  const usePatientListSuspense = useMemo(() => willUseSuspense((jwt) => getFetcher(`/api/v2/provider/${providerInfo.id}/users`, undefined, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${jwt}`
    }
  }),[providerInfo]))

  return <Suspense fallback={null}>
    <Router primary={false} component={Identity}>
      <ProviderLogin setJwt={setJwt} providerInfo={providerInfo} path="login"/>
      <PatientList jwt={jwt} usePatientListSuspense={usePatientListSuspense} providerInfo={providerInfo} default/>
    </Router>
  </Suspense>
}
