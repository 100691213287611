import {getFetcherWithResponse} from '@startlibs/utils'

export const downloadFile =  (launchURL) => {
  if (window.navigator && window.navigator.msSaveBlob) {
    window.location.href = launchURL
  } else {
    const link = document.createElement('a')
    link.href = launchURL
    link.style.visibility = "hidden"
    link.setAttribute("download","")
    document.body.appendChild(link);
    link.click()
    link.remove()
  }
}

var getOrigin = function(href) {
  const [protocol,,host] = href.split("/")
  return protocol + "//"+ host;
};


export const checkForDownload = (url) => new Promise((res, rej) => {
  let tries = 0;
  if (window.xdomain) {
    const origin = getOrigin(url)
    window.xdomain.slaves({
      [origin]:"/public/proxy.html"
    })
  }
  const check = () => getFetcherWithResponse(url, null, {method: 'HEAD'})
    .then(([, response]) => response.status === 200 ? res(url) : setTimeout(check, 1000))
    .catch(([req,response]) => {
      if (!response) {
        tries++;
        if (tries > 5) {
          rej(response)
        } else {
          setTimeout(check, 1000)
        }
      } else if (response.status === 404) {
        rej(response.status)
        console.log('missing file')
      } else if (response.status === 500) {
        rej(response.status)
        console.log('server error')
      }
    })
  check()
})