import { useToggle } from '@startlibs/core';
import React from 'react'
import styled, { css } from 'styled-components';

import purviewLogo from '../../../public/favicon.png'
import {AcceptTermsDialog} from '../pages/recordList/AcceptTermsDialog'

export const PurviewFooter = styled(({className, absolute, providerInfo, jwt}) => {
  const termsDialog = useToggle()

  return <>
    <div className={className} absolute={absolute}>
      <div className="license-link"><a href="#" onClick={e => {e.preventDefault(); e.stopPropagation(); termsDialog.open()}}>License Terms</a></div>
      <div className="footer-line">
        <a href="https://www.purview.net/" target="_blank"><img src={purviewLogo} alt="Purview Logo"/></a>
        <div>
          <div>
            <span className="main-text">Powered by <a href="https://www.purview.net/" target="_blank">Purview</a></span> © <span>{new Date().getFullYear()}</span>. All Rights Reserved.
          </div>
        </div>
      </div>
    </div>
    {
      termsDialog.isOpen && <AcceptTermsDialog
        closeDialog={termsDialog.close}
        providerInfo={providerInfo}
        jwt={jwt}
      />
    }
    </>
})`
  color: rgba(0,0,0,0.4);
  font-size: 12px;
  padding: 0.5rem 1rem;
  position: relative;
  bottom: .25rem;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  z-index: 2;
  margin: -40px auto 0px;
  .license-link {
    flex-grow: 1;
    flex-basis: 100%;
    text-align: center;
  }
  .footer-line {
    flex-grow: 1;
    flex-basis: 100%;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  a {
    color: rgba(0,0,0,0.5);
    :hover {
      color: rgba(0,0,0,0.6);
    }
  }
  img {
    width: 25px;
    height: 25px;
    margin-right: 0.5rem;
  }
  .main-text {
    color: rgba(0,0,0,0.5);
    font-weight: 600;
  }
  @media print {
    display: none !important;
  }
  ${props => props.absolute && css`
    position: absolute;
    bottom: .25rem;
    left: 50%;
    transform: translateX(-50%);
  `}
`
