import { ContextMenu, Icon, Li, Loading, Tooltip } from '@startlibs/components';
import {stopPropagation,formatDate, getColor, media} from '@startlibs/utils'
import {usePopupToggle, useToggle} from '@startlibs/core'
import React from 'react'
import _ from 'lodash/fp'
import styled, {css} from 'styled-components'

import {DownloadErrorDialog} from 'sharecomponents/src/pages/DownloadErrorDialog'
import {ShareDialog} from 'sharecomponents/src/pages/ShareDialog'

import {ACCESSION_NUMBER, getRecordID, getRecordType, STUDY_UID, STUDY_DATE} from '../../enums/RecordType'
import {CreditCardCheckout} from '../checkout/CreditCardCheckout'
import {DropDownIcon, TextButton} from '../../components/StyledComponents'
import {authGetFetcher} from '../../utils/authFetch'
import {checkForDownload, downloadFile} from '../../utils/downloadFile'
import { formatCurrency, formatDateNoUTC } from '../../utils';
import { createPromiseQueue } from '@startlibs/utils'

export const downloadQueue = createPromiseQueue()

const RecordItem = styled.div`
  :nth-child(even) {
    background: rgba(41, 122, 163, 0.07);
    :hover {
      background: rgba(41, 122, 163, 0.09);
    }
  }
  :hover {
    background: rgba(0,0,0, 0.02);
    color: ${getColor('gray60')};
  }
  ${props => props.locked && css`
    :nth-child(even) {
      background: rgba(0, 0, 0, 0.05);
      :hover {
        background: rgba(0, 0, 0, 0.04);
      }
    }
    :nth-child(odd) {
      background: rgba(0, 0, 0, 0.08);
      :hover {
        background: rgba(0, 0, 0, 0.07);
      }
    }
  `}
  ${props => props.selecting && css`
    cursor: pointer;
    :hover {
      background-color: rgba(255,255,255,0.2);
    }
    ${props => props.isSelected && css`
      :nth-child(even), :nth-child(odd) {
        background: white;
        :hover {
          background: white;
        }
      }
    `}
  `}
  ${media.max(799)`
    :nth-child(even), :nth-child(odd), :nth-child(even):hover, :nth-child(odd):hover {
      background: white;
      ${props => props.locked && css`
        background: ${getColor('gray240')};
      `}
      ${props => props.selecting && css`
        cursor: pointer;
        :hover {
          background-color: rgba(255,255,255,0.2);
        }
        ${props => props.isSelected && css`
          :nth-child(even), :nth-child(odd) {
            background: white;
            :hover {
              background: white;
            }
          }
        `}
      `}
    }
    border: 1px solid rgba(0,0,0,0.15);
    margin-bottom: 0.75rem;
    border-radius: 5px;
  `}
  .standard-info {
    .locked-icon {
      position: absolute;
      left: -.75rem;
      top: 50%;
      transform: translateY(-50%);
      width: 1.5rem;
      height: 1.5rem;
      font-size: 12px;
      background: ${getColor('gray150')};
      border-radius: 1rem;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    ${media.max(799)`
      display: block;
      padding: 1.5rem;
      position: relative;
      .locked-icon {
        top: 1.5rem;
        transform: none;
      }
      .study-description {
        font-weight: 600;
        font-size: 13px;
        ${props => props.locked && css` padding-right: 120px;`}
      }
      .referring-physician {
        margin-top: 0.25rem;
        color: ${getColor('gray120')};
        :before {
          content: "Referring physician: ";
        }
      }
      .study-actions {
        display: flex;
        margin: 1.25rem -1.5rem -1.5rem;
        padding: 0.25rem;
        background: rgba(41, 122, 163, 0.07);
        > * {
          flex-grow: 1;
          text-align: center;
          flex-shrink: 0;
          margin: 0;
        }
      }
      ${props => props.locked && css`
        .study-actions {
          padding: 0;
        }
      `}
      ${props => props.selecting && css`
        .study-actions {
          position: absolute;
          top: -.5rem;
          right: 2.25rem;
          background: transparent;
        }
      `}
    `}
    ${media.min(800)`
      display: flex;
      align-items: center;
      min-height: 4rem;
      position: relative;
      border-top: 1px solid rgb(220,220,220);
      > div {
        padding: 0.75rem 1rem;
      }
      .referring-physician {
        ${props => !props.groupedByAccession && !props.groupedByDate && css`
          order: -1;
        `}
      }
      ${props => props.groupedByAccession && css`
        .study-description { order: -1; }
        .date { order: 0; }
        .referring-physician { order: 1; }
        .study-actions { order: 2; }
      `}
      .study-actions {
        display: flex;
        flex-basis: inherit;
        flex-shrink: 0;
        padding: 0.75rem;
      }
    `}
  }
`

const UnlockButton = styled(TextButton)`
  ${media.max(799)`
    position: absolute;
    top: 1rem;
    right: 1rem;
  `}
`

const ViewButton = styled(TextButton)`
  padding-right: 1.5rem;
  ${DropDownIcon} {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: .5rem;
  }
`

const CheckboxIcon = styled(Icon)`
  width: 2rem;
  height: 2rem;
  line-height: 22px;
  text-align: center;
  color: white;
  border-radius: 50%;
  border: 1px solid ${props => props.selected ? getColor('main') : getColor('gray210')};
  background: ${props => props.selected ? getColor('main') : 'white'};
  font-size: 18px;
  margin-right: 0.25rem;
`

const ExamIdentification = styled.div`
  .study-info {
    color: rgba(0,0,0,0.7);
    margin-top: 2px;
    :first-child {
      font-size: 14px;
      font-weight: 600;
      color: inherit;
      margin-top: 0;
      margin-bottom: .25rem;
    }
`

const StudyField = styled.div`

`

export const Counters = styled.div`
  display: flex;
  ${media.max(799)`
    flex-wrap: wrap;
  `}
  > div {
    display: flex;
    align-items: center;
    color: ${getColor('gray120')};
    ${Icon} {
      font-size: 16px;
      margin-right: .25rem;
    }
  }
`

const AccessionField = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  &.study-description {
    ${props => props.canExpand && css`
      cursor: pointer;
    `}
  }
  .accession-id {
    display: flex;
    align-items: center;
    flex-basis: 0;
    width: 100%;
  }
  ${Icon} {
    font-size: 16px;
    margin-right: .25rem;
    transition: all 0.25s ease;
  }
  .accession-number {
    font-size: 13px;
    margin-right: 1rem;
    width: 100%;
    > div {
      width: 100%;
      vertical-align: -3px;
      > div {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }
  ${Counters} {
    flex-grow: 1;
    flex-shrink: 1;
    min-width: 140px;
    display: flex;
    ${media.max(799)`
      min-width: 145px;
    `}
    > div {
      margin-right: .75rem;
      display: flex;
      align-items: center;
      color: ${getColor('gray120')};
      ${Icon} {
        font-size: 16px;
        margin-right: .25rem;
      }
    }
  }
`

const ExpandedRecordsWrapper = styled.div`
  padding: .5rem;
  margin: 0 1rem;
  display: flex;
  flex-wrap: wrap;
  border-top: 1px solid rgba(0,0,0,0.15);
  ${media.max(799)`
    padding: 1rem;
    ${props => !props.locked && css`
      border-top: 0;
    `}
  `}
`

const SubItemWrapper = styled.div`
  flex-basis: 23%;
  flex-shrink: 0;
  flex-grow: 0;
  min-width: 155px;
  margin: .5rem;
  padding: .5rem;
  border-radius: 5px;
  color: ${getColor('gray120')};
  background: rgba(0,0,0,0.025);
  ${media.max(799)`
    flex-basis: 50%;
    flex-grow: 1;
  `}
`

const ExpandedRecordSubItem = styled.div`
  font-weight: 600;
  margin-right: .5rem;
  display: flex;
  ${props => props.tiedToStudy && css`
    font-weight: 400;
  `}
  .desc-text {
    ${props => !props.locked && css`
      cursor: pointer;
    `}
    display: flex;
    position: relative;
    max-width: 100%;
    .record-icon {
      font-size: 16px;
      margin-right: .25rem;
      vertical-align: -2px;
    }
    > div { /* Tooltip wrapper */
      display: flex;
      flex-grow: 1;
      max-width: calc(100% - 95px);
      ${props => props.locked && css`
        max-width: calc(100% - 85px);
      `}
      .inner-name {
        display: inline-block;
        width: 100%;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        margin-right: .25rem;
      }
    }
    .no-shrink {
      flex-shrink: 0;
      flex-basis: 85px;
      .context-icon {
        margin-top: 3px;
        margin-left: .25rem;
      }
    }
  }
  @media all and (-ms-high-contrast:none) /* IE fixes */
   {
     display: block;
     *::-ms-backgrop, & { display: block; }
   }
`

const SubLoader = styled.div`
  display: flex;
  padding-bottom: .25rem;
  ${Loading} {
    margin-right: .5rem;
  }
`

export const Modality = styled.div `
  border-radius: 20px;
  background: rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(0, 0, 0, 0.075);
  display: inline-block;
  padding: 0px 6px;
  color: rgba(0, 0, 0, 0.6);
  white-space: nowrap;
  margin-left: 0.5rem;
`

export const RecordRow = ({record, jwt, selecting, acceptedTerms, isSelected, toggleSelection, recordAccessFee, hasManyUnpaid, payStudies, providerInfo}) => {
  const downloadLoading = useToggle([])
  const started = useToggle([])
  const viewLoading = useToggle([])
  const downloadMenu = usePopupToggle()
  const contextMenu = usePopupToggle()
  const viewMenu = usePopupToggle()
  const downloadError = useToggle()
  const expanded = useToggle()

  const paymentDialog = useToggle()
  const shareDialog = useToggle()
  const shareToken = useToggle()
  const shareJwtLoading = useToggle()

  const openShareDialog = (record) => {
    const type = getRecordType(record)
    const id = getRecordID(record)
    shareDialog.openWith(record)
    if (!shareToken.isOpen) {
      shareJwtLoading.wait(
        authGetFetcher(`/api/v2/system/jwt/${id}?type=${type}&nocache=${new Date().getTime()}`)
          .then(({jwt}) => shareToken.openWith(jwt))
      )
    }
  }

  const openViewer = (record) => {
    const wi = window.open('about:blank', '_blank')
    const type = getRecordType(record)
    const id = getRecordID(record)
    viewLoading.wait(
      authGetFetcher(`/api/v2/viewerLink/${id}?type=${type}&nocache=${new Date().getTime()}`)
        .then(({viewerURL}) => {
          wi.location.href = viewerURL;
        })
        .catch(wi.close),
      _.concat([record]),
      _.without([record])
    )
  }

  const download = async (record, downloadType) => {
    const type = getRecordType(record)
    const id = getRecordID(record)

    downloadLoading.openWith(_.concat([record]))
    const {
      value: [unlock],
    } = await downloadQueue.next()
    try {
      const {url} = await authGetFetcher(`/api/v2/downloadLink/${downloadType}/${id}?type=${type}&nocache=${new Date().getTime()}`)

      if (downloadType === 'regular') {
        downloadFile(url)
      } else {
        const downloadUrl = await checkForDownload(url)
        downloadFile(downloadUrl)
      }

      started.openWith(_.concat([record]));
      setTimeout(() => started.openWith(_.without([record])), 1500)
    } catch (e) {
      downloadError.open()
    } finally {
      downloadLoading.openWith(_.without([record]))
      unlock()
    }
  }

  const locked = recordAccessFee > 0 && !record.paid

  const reportCount = (record.studies || []).filter(_.get('report')).length + (record.report ? 1 : 0)
  const hasReport = reportCount > 0

  return <><RecordItem
    locked={locked}
    isSelected={isSelected}
    selecting={selecting}
    onClick={selecting ? toggleSelection : undefined}
    groupedByAccession={providerInfo.recordType === ACCESSION_NUMBER}
    groupedByDate={providerInfo.recordType === STUDY_DATE}
  >
    <div className="standard-info">
      {
        locked && <Icon className="locked-icon" icon='lock'/>
      }
      {
        providerInfo.recordType === STUDY_UID
          ? <StudyCells record={record}/>
          : providerInfo.recordType === ACCESSION_NUMBER
          ? <AccessionCells expandedToggle={expanded} record={record}/>
          : <DateCells  expandedToggle={expanded} record={record}/>
      }
      {
        selecting
          ? <div className="study-actions">
            <CheckboxIcon icon="check" selected={isSelected}/>
          </div>
          : <div className="study-actions">
            {
              locked &&
              <UnlockButton onClick={paymentDialog.open}><Icon icon="unlock"/>Unlock for {providerInfo.currency.symbol}{formatCurrency(recordAccessFee)}
              </UnlockButton>
            }
                <TextButton
                  disabled={locked}
                  onClick={downloadLoading.isOpen.includes(record) ? undefined : downloadMenu.open}
                  focused={downloadMenu.isOpen}
                  isLoading={downloadLoading.isOpen.includes(record)}
                  started={started.isOpen.includes(record)}
                  css="min-width:106px;"
                >
                  {
                    downloadLoading.isOpen.includes(record) ?
                      <><Loading size={15} borderWidth={3}/>Preparing...</>
                      :
                      started.isOpen.includes(record) ?
                        <><Icon icon="check"/>Started</>
                        :
                        <><Icon icon="download"/>Download<DropDownIcon icon="arrow-down"/></>
                  }
                  {
                    downloadMenu.isOpen &&
                    <ContextMenu>
                      <Li onClick={() => download(record, 'regular')} label="Download Images"/>
                      <Li onClick={() => download(record, 'cd')} label="Download for CD (with viewer)"/>
                      {hasReport && <Li
                        onClick={() => window.open(`/api/v2/getReport/${getRecordID(record)}?type=${providerInfo.recordType}&jwt=${jwt}`,"_blank")}
                        label="Download Report"
                      />}
                    </ContextMenu>
                  }
                </TextButton>
                <ViewButton
                  disabled={locked}
                  onClick={hasReport ? viewMenu.open : () => openViewer(record)}
                  isLoading={viewLoading.isOpen.includes(record)}
                >{viewLoading.isOpen.includes(record) ? <><Loading
                  size={15}
                  borderWidth={3}
                />Preparing...</> : <><Icon
                  icon="view"
                />View{hasReport && <DropDownIcon icon="arrow-down"/>}</>}
                  {
                    viewMenu.isOpen &&
                    <ContextMenu>
                      <Li onClick={() => openViewer(record)} label="View Images"/>
                      <Li
                        onClick={() => window.open(`/api/v2/viewReport/${getRecordID(record)}?type=${providerInfo.recordType}&jwt=${jwt}`,"_blank")}
                        label="View Report"
                      />
                    </ContextMenu>
                  }
                </ViewButton>
                <TextButton disabled={locked} onClick={() => openShareDialog(record)} isLoading={shareDialog.isOpen}><Icon
                  icon="send"
                />Share</TextButton>
          </div>
      }
      {downloadError.isOpen && <DownloadErrorDialog closeDialog={downloadError.close}/>}
      {
        paymentDialog.isOpen &&
        <CreditCardCheckout
          acceptedTerms={!!acceptedTerms.isOpen}
          stripeKey={providerInfo.stripeKey}
          providerInfo={providerInfo}
          jwt={jwt}
          payStudies={payStudies}
          selectMore={hasManyUnpaid && (() => {
            toggleSelection();
            paymentDialog.close()
          })}
          closeDialog={paymentDialog.close}
          studies={[record]}
          recordAccessFee={recordAccessFee}
        />
      }
      {shareDialog.isOpen && <ShareDialog
      transform={({emails, ...sharingOptions}) =>
        ({
          mailsTo: _.filter(email => email, emails),
          sharingOptions
        })}
        labels={shareDialog.isOpen.studies?.length > 1 ? {firstStepTitle: "Share medical records", reportToggle: "Include report(s)"} : {}}
        infoCard={
          !shareDialog.isOpen.studyUID
            ? <AccessioNumberShareInfoCard record={shareDialog.isOpen}/>
            : undefined
        }
        closeDialog={() => {
          shareDialog.close();
          shareToken.close()
        }} jwt={shareToken.isOpen}
      />}
    </div>
    {
      expanded.isOpen &&
      <ExpandedRecord
        jwt={jwt}
        contextMenu={contextMenu}
        record={record}
        openShareDialog={openShareDialog}
        openViewer={openViewer}
        viewLoading={viewLoading}
        download={download}
        downloadLoading={downloadLoading}
        locked={locked}
      />
    }
  </RecordItem>
  </>

}

const AccessioNumberShareInfoCard = ({record}) => {
  const studyCount = record.studies.length
  const reportCount = record.studies.filter(_.get('report')).length + (record.report ? 1 : 0)

  return <ExamIdentification>
    <div className="study-info">
      Exam: {record.accessionNumber}
    </div>
    <Counters>
      <div><Icon icon="dicom-no-text"/> {studyCount} scan{studyCount !== 1 && 's'}{_.uniq(record.studies.map(_.get("modality"))).map(modality => <Modality>{modality}</Modality>)}</div>
    </Counters>
    <div className="study-info">
      Referring physician: {record.referringPhysician}
    </div>
  </ExamIdentification>
}

const StudyCells = ({record}) => <>
  <StudyField className="study-description">
    {record.fullDescription}
  </StudyField>
  <StudyField className="referring-physician">
    {record.referringPhysician}
  </StudyField>
</>

const AccessionCells = ({record, expandedToggle}) => {
  const accessionNumber = record.accessionNumber
  const studyCount = record.studies.length
  const reportCount = record.studies.filter(_.get('report')).length + (record.report ? 1 : 0)

  const referringPhysician = record.referringPhysician

  const startDate = formatDate(new Date(Number(_.minBy(_.get('studyDate'), record.studies).studyDate)), "MM/dd/yyyy", null, true)
  const endDate = formatDate(new Date(Number(_.maxBy(_.get('studyDate'), record.studies).studyDate)), "MM/dd/yyyy", null, true)

  const canExpand = true//(record.studies.length + (record.report ? 1 : 0)) > 1

  return <>
    <AccessionField className="study-description" onClick={canExpand && stopPropagation(expandedToggle.toggle)} canExpand={canExpand}>
      <div className="accession-id">
        {canExpand &&
        <Icon icon="arrow-down-line" css={!expandedToggle.isOpen && 'display:inline-block;transform: rotate(-90deg);'}/>
        }
        <div className="accession-number">
          <Tooltip whenEllipsis={({target}) => target.offsetWidth < target.scrollWidth} content={accessionNumber}>
            <div>{accessionNumber}</div>
          </Tooltip>
        </div>
      </div>
      <Counters>
        <div><Icon icon="dicom-no-text"/> {studyCount} scan{studyCount !== 1 && 's'}{_.uniq(record.studies.map(_.get("modality"))).map(modality => <Modality>{modality}</Modality>)}</div>
      </Counters>
    </AccessionField>
    <AccessionField className="referring-physician">
      {referringPhysician}
    </AccessionField>
    <AccessionField className="date">
      {startDate === endDate ? <span>{startDate}</span> : <span>{startDate} {endDate}</span>}
    </AccessionField>
  </>
}

const DateCells = ({record, expandedToggle}) => {
  const studyCount = record.studies.length
  const reportCount = record.studies.filter(_.get('report')).length + (record.report ? 1 : 0)


  const date = formatDate(new Date(Number(record.studyDate)), "MM/dd/yyyy")
  const referringPhysicians = _.uniq(record.studies.map(_.get('referringPhysician')))

  const canExpand = true//(record.studies.length + (record.report ? 1 : 0)) > 1

  return <>
    <AccessionField className="study-description" onClick={canExpand && stopPropagation(expandedToggle.toggle)} canExpand={canExpand}>
      <div className="accession-id">
        {canExpand &&
        <Icon icon="arrow-down-line" css={!expandedToggle.isOpen && 'display:inline-block;transform: rotate(-90deg);'}/>
        }
        <div className="accession-number">
          <Tooltip whenEllipsis={({target}) => false}>
            <div>{date}</div>
          </Tooltip>
        </div>
      </div>
      <Counters>
        <div><Icon icon="dicom-no-text"/> {studyCount} scan{studyCount !== 1 && 's'}{_.uniq(record.studies.map(_.get("modality"))).map(modality => <Modality>{modality}</Modality>)}</div>
      </Counters>
    </AccessionField>
    <AccessionField className="referring-physician">
      {referringPhysicians.join(", ")}
    </AccessionField>
  </>
}


const ExpandedRecord = ({record, contextMenu, openShareDialog, openViewer, viewLoading, download, downloadLoading, locked, jwt}) =>
  <ExpandedRecordsWrapper locked={locked}>
    {record.studies.map(study =>
      <React.Fragment key={study.studyUID}>
        <SubItemWrapper>
          <ExpandedRecordStudy
            study={study}
            openViewer={openViewer}
            contextMenu={contextMenu}
            viewLoading={viewLoading}
            download={download}
            openShareDialog={openShareDialog}
            downloadLoading={downloadLoading}
            locked={locked}
          />
          {
            study.report &&
            <ExpandedRecordReport
              report={study.report}
              contextMenu={contextMenu}
              uid={study.studyUID}
              jwt={jwt}
              type={STUDY_UID}
              locked={locked}
              tiedToStudy
            />
          }
        </SubItemWrapper>
      </React.Fragment>
    )}
    {
      record.report &&
      <SubItemWrapper>
        <ExpandedRecordReport
          contextMenu={contextMenu}
          report={record.report}
          uid={getRecordID(record)}
          jwt={jwt}
          type={getRecordType(record)}
          locked={locked}
        />
      </SubItemWrapper>
    }
  </ExpandedRecordsWrapper>

const ExpandedRecordStudy = ({study, contextMenu, openShareDialog, openViewer, viewLoading, download, downloadLoading, locked}) =>
  <ExpandedRecordSubItem locked={locked}>
    {downloadLoading.isOpen.includes(study) ?
      <SubLoader><Loading size={15} borderWidth={3} /> Preparing...</SubLoader>
      : viewLoading.isOpen.includes(study) ?
        <SubLoader><Loading size={15} borderWidth={3} /> Opening viewer... </SubLoader>
        : <div className="desc-text" onClick={!locked && contextMenu.willOpenWith(study)}>
          <Icon className="record-icon" icon="dicom-no-text"/>
            <Tooltip whenEllipsis={({target}) => target.offsetWidth < target.scrollWidth}  content={study.modality + " " + study.studyDescription}>
              <span className="inner-name">{study.modality} {study.studyDescription && " - " + study.studyDescription} </span>
            </Tooltip>
            <span className="no-shrink">
              <span> ({formatDate(new Date(study.studyDate), 'yyyy-MM-dd')})</span>
              {!locked && <Icon  className="context-icon" icon='arrow-down' />}
            </span>
            {
              contextMenu.isOpen === study && <ContextMenu>
                <Li label="Download images" icon="download" onClick={() => download(study, 'regular')}/>
                <Li label="Download for CD" icon="download" onClick={() => download(study, 'cd')}/>
                <Li label="View images" icon="view" onClick={() => openViewer(study)}/>
                <Li label="Share images" icon="send" onClick={() => openShareDialog(study)}/>
              </ContextMenu>
            }
        </div>
        }
  </ExpandedRecordSubItem>

/*

            post("/records/pay/"){
                RecordsController.payRecords(call)
            }

            get("/system/jwt/{recordUid}"){
                call.respond(PacsController.createSystemPrincipalJWT(call))
            }

            get("/viewerLink/{recordUid}") {
                RecordsController.viewRecord(call)
            }

            get("/downloadLink/{downloadType}/{recordUid}") {
                RecordsController.getDownloadUrl(call)
            }

 */

const ExpandedRecordReport = ({report, contextMenu, uid, type, jwt, locked, tiedToStudy}) =>
  <ExpandedRecordSubItem locked={locked}  tiedToStudy={tiedToStudy}>
    <div className="desc-text" onClick={!locked && contextMenu.willOpenWith(report)}>
      <Icon className="record-icon" icon="checked-report"/>
      <Tooltip whenEllipsis={({target}) => target.offsetWidth < target.scrollWidth} content={report.description}>
        <span className="inner-name">{report.description}</span>
      </Tooltip>
      <span className="no-shrink">
        <span> ({formatDateNoUTC(new Date(report.lastUpdated), 'yyyy-MM-dd')})</span>
        {!locked && <Icon className="context-icon" icon='arrow-down'/>}
      </span>
      {
        contextMenu.isOpen === report && <ContextMenu>
          <Li
            icon="download"
            label="Download Report"
            onClick={() => window.open(`/api/v2/getReport/${uid}?type=${type}&jwt=${jwt}`,"_blank")}
            target="_blank"
          />
          <Li
            icon="view"
            label="View Report"
            onClick={() => window.open(`/api/v2/viewReport/${uid}?type=${type}&jwt=${jwt}`,"_blank")}
            target="_blank"
          />
        </ContextMenu>
      }
    </div>
  </ExpandedRecordSubItem>
