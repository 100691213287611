
export const STUDY_UID = process.env.NODE_ENV === 'development' ? 'STUDY_UID' : 'STUDY_UID'
export const ACCESSION_NUMBER = process.env.NODE_ENV === 'development' ? 'ACCESSION_NUMBER' : 'ACCESSION_NUMBER'
export const STUDY_DATE = process.env.NODE_ENV === 'development' ? 'STUDY_DATE' : 'STUDY_DATE'

export const getRecordType = (record) =>
 record.studyUID
  ? STUDY_UID
  : record.accessionNumber
    ? ACCESSION_NUMBER
    : STUDY_DATE

export const getRecordID = (record) =>
  record.studyUID || record.accessionNumber || record.studyDate

export const ACCESSION_KEY = process.env.NODE_ENV === 'development' ? 'accessionNumber' : 'accessionNumber'