import { Loading } from '@startlibs/components';
import {Router} from '@reach/router'
import { StartlibsProvider, Slot, useToggle, useTitle } from '@startlibs/core';
import {getFetcher} from '@startlibs/utils'
import React, {Suspense, useEffect} from 'react'
import styled, { ThemeProvider } from 'styled-components';

import { HelpButton } from 'sharecomponents/src/components/HelpButton';
import {Notifications} from 'sharecomponents/src/components/Notifications'
import {ProviderErrorBoundary} from 'sharecomponents/src/pages/errors/ProviderError'
import {SPA_ORIGIN} from 'sharecomponents/src/pages/ShareDialog'
import {ShareLogin} from 'sharecomponents/src/pages/ShareLogin'
import {SharedStudy} from 'sharecomponents/src/pages/SharedStudy'
import {useProviderInfo} from 'sharecomponents/src/hooks/useProviderInfo'
import {willUseSuspense, useSuspense} from 'sharecomponents/src/hooks/useSuspense'

import {Login} from './pages/Login'
import {ProviderRoutes} from './pages/provider/ProviderRoutes'
import {RecordList} from './pages/RecordList'
import {jwtGetFetcher} from './utils/authFetch'
import StartlibsConfig from './UIConfig/StartlibsConfig'
import StyledTheme from './UIConfig/StyledTheme'

if (process.env.NODE_ENV === 'development') {
  window.SHARE_ORIGIN = 'https://shareservice.vivacloud.io'
}

export const LoadingPage = styled(({className}) => <div className={className}>
  <Loading size={40} borderWidth={6}/>
</div>)`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  * {
    margin-left: auto;
    margin-right: auto;
  }
  img {
    max-width: 11rem;
    margin-bottom: 4rem;
  }
`

export const App = () => {

  const maxDuration = useToggle(1)
  useEffect(() => {
    setTimeout(() => maxDuration.openWith(10000), 1000)
  }, [])

  return <StartlibsProvider value={StartlibsConfig}>
    <ThemeProvider theme={StyledTheme}>
      <>
        <ProviderErrorBoundary>
          <Suspense fallback={<LoadingPage/>} maxDuration={maxDuration.isOpen}>

            <Router component={Identity}>
              <SharedPages path="/share/:jwt" login/>
              <SharedPages path="/shared/:jwt"/>
              <ProviderRoutes path="/admin/*"/>
              <SharingPages default/>
            </Router>
          </Suspense>
        </ProviderErrorBoundary>
        <Notifications/>
        <HelpButton/>
        <div style={{position: 'absolute', top: 0, left: 0, right: 0, zIndex: 100}}>
          <Slot name="Popup"/>
        </div>
        <div style={{position: 'absolute', top: 0, left: 0, right: 0, zIndex: 200}}>
          <Slot name="Portal"/>
          <Slot name="Dialog"/>
          <Slot name="Confirm-Dialog"/>
          <Slot name="Notification"/>
          <Slot name="Notifications"/>
        </div>
      </>
    </ThemeProvider>
  </StartlibsProvider>
}

const Identity = React.forwardRef(({children}, ref) => <React.Fragment>{children}
  <div ref={ref}/>
</React.Fragment>)

const SharingPages = () => {
  const [providerInfo] = useProviderInfo(useSuspense('/api/v2/provider'))

  useTitle(providerInfo.name)

  return <Router component={Identity}>
    <RecordList path="/studies" providerInfo={providerInfo}/>
    <Login default providerInfo={providerInfo}/>
  </Router>
}

const useShareInfo = willUseSuspense((jwt) => getFetcher(SPA_ORIGIN() + '/api/share/info?system=patientaccess&noCache='+Date.now(), undefined, {
  headers: {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${jwt}`
  }
}))

const SharedPages = ({jwt, login, navigate}) => {
  const [{providerInfo, authenticationResponse, ...shareInfo}] = useProviderInfo(useShareInfo(jwt))
  useTitle(providerInfo ? providerInfo.name : "")

  if (shareInfo.url) {
    window.location = shareInfo.url
    return <LoadingPage/>
  }

  return login
    ? <ShareLogin
      dobSubmitFormat="yyyy/MM/dd"
      providerInfo={providerInfo}
      authenticationResponse={authenticationResponse}
      shareInfo={shareInfo}
      navigate={navigate}
      token={jwt}
    />
    : <SharedStudy
      providerInfo={providerInfo}
      token={jwt}
      navigate={navigate}
      openReport={({reportJWT,SPA_ORIGIN}) =>
        jwtGetFetcher(reportJWT)(SPA_ORIGIN() + "/api/reports/view")
          .then(({url}) => window.location = url)
          .then(() => new Promise(() => {}))
      }
      downloadReport={({reportJWT,SPA_ORIGIN}) =>
        jwtGetFetcher(reportJWT)(SPA_ORIGIN() + "/api/reports/download")
          .then(({url})=>url)
      }
    />
}
