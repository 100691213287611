import {Icon} from '@startlibs/components'
import {getColor, media} from '@startlibs/utils'
import React from 'react'
import styled, {css} from 'styled-components'

import { ACCESSION_NUMBER, STUDY_UID, STUDY_DATE } from '../../enums/RecordType';
import {RecordRow} from './RecordRow'
import {TextButton} from '../../components/StyledComponents'


const StudyListTable = styled.div`
  width: 100%;
  color: ${getColor('gray90')};
  ${media.min(800)`
    border-radius: 5px;
    background: white;
    border: 1px solid rgba(0,0,0,0.15);
    .referring-physician {
      flex-basis: 21%;
      flex-shrink: 0;
      padding-left: 1.25rem;
    }
    .study-description {
      flex-grow: 1;
    }
  `}
  ${props => props.groupedByAccession && css`
    ${media.min(800)`
      .study-description {
        flex-grow: 1;
        min-width: 140px;
        max-width: 300px;
        flex-basis: 140px;
      }
      .referring-physician {
        flex-grow: 0;
        flex-shrink: 1;
        flex-basis: 180px;
        max-width: 180px;
        min-width: 130px;
        padding-left: .75rem;
      }
      .date {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: 90px;
      }
      .study-actions {
        flex-basis: 398px;
        min-width: 398px;
        flex-grow: 0;
        flex-shrink: 0;
        justify-content: flex-end;
        display: flex;
      }
      .study-description { order: -1; }
      .date { order: 0; }
      .referring-physician { order: 1; }
      .study-actions { order: 2; }
    `}
    .study-description {
      @media all and (-ms-high-contrast:none) /* IE fixes */
       {
         display: block;
         *::-ms-backgrop, & { display: block; }
         .accession-number { width: 100%; }
         *::-ms-backdrop, .accession-number { width: 100%; }
       }
      @media (max-width: 760px) and (-ms-high-contrast:none) {
        display: block;
        max-width: 100%;
        width: 100%;
        *::-ms-backgrop, & { max-width: 100%; width: 100%; display: block; }
      }
    }
  `}
  ${props => props.groupedByDate && css`
    ${media.min(800)`
      .study-description {
        flex-grow: 1;
        min-width: 200px;
        max-width: 300px;
        flex-basis: 200px;
      }
      .referring-physician {
        flex-grow: 0;
        flex-shrink: 1;
        flex-basis: 180px;
        max-width: 180px;
        min-width: 135px;
        padding-left: .75rem;
      }
      .study-actions {
        flex-basis: 398px;
        min-width: 398px;
        flex-grow: 0;
        flex-shrink: 0;
        justify-content: flex-end;
        display: flex;
        margin-left: auto;
      }
    `}
    .study-description {
      @media all and (-ms-high-contrast:none) /* IE fixes */
       {
         display: block;
         *::-ms-backgrop, & { display: block; }
         .accession-number { width: 100%; }
         *::-ms-backdrop, .accession-number { width: 100%; }
       }
      @media (max-width: 760px) and (-ms-high-contrast:none) {
        display: block;
        max-width: 100%;
        width: 100%;
        *::-ms-backgrop, & { max-width: 100%; width: 100%; display: block; }
      }
    }
  `}
`


const TableHead = styled.div`
  display: none;
  ${media.min(800)`
    font-weight: 600;
    display: flex;
    align-items: center;
    > div {
      padding: 0.75rem 1rem;
    }
    .record-selection-help-text {
      font-weight: 400;
      color: rgba(0,0,0,0.5);
    }
    .unlock-multiple-records-button {
      justify-content: flex-end;
      margin-right: .5rem;
    }
    .study-actions {
      padding: .75rem 0;
    }
  `}
`


const StudyHeader = () => <>
  <div className="referring-physician">
    Referring Physician
  </div>
  <div className="study-description">
    Exam Description
  </div>
</>

const AccessionHeader = () => <>
  <div className="study-description">
    Exams
  </div>
  <div className="referring-physician">
    Referring Physician
  </div>
  <div className="date">
    Date
  </div>
</>

const DateHeader = () => <>
  <div className="study-description">
    Exams
  </div>
  <div className="referring-physician">
    Referring Physician
  </div>
</>

export const RecordsTable = ({records, acceptedTerms, jwt, hasManyUnpaid, checkoutSelectedRecords, recordAccessFee, payRecords, providerInfo}) => <>
  <StudyListTable groupedByAccession={providerInfo.recordType === ACCESSION_NUMBER} groupedByDate={providerInfo.recordType === STUDY_DATE}>
    <TableHead>
      {
        providerInfo.recordType === STUDY_UID
          ? <StudyHeader />
          : providerInfo.recordType === ACCESSION_NUMBER
          ? <AccessionHeader />
          : <DateHeader />
      }
      <div className="study-actions">
        {
          hasManyUnpaid && recordAccessFee > 0 && (
            checkoutSelectedRecords.isOpen
              ?
              <div className="record-selection-help-text">Select the records you'd like to unlock and confirm bellow</div>
              : <TextButton className="unlock-multiple-records-button" onClick={checkoutSelectedRecords.willOpenWith([])}><Icon
                icon="unlock"
              />Unlock multiple records</TextButton>
          )
        }
      </div>
    </TableHead>
    {
      records.map(record => {
        if (record.paid && checkoutSelectedRecords.isOpen)
          return
        const isSelected = checkoutSelectedRecords.isOpen && checkoutSelectedRecords.isOpen.indexOf(record) >= 0
        return <RecordRow
          acceptedTerms={acceptedTerms}
          providerInfo={providerInfo}
          isSelected={isSelected}
          payStudies={payRecords}
          recordAccessFee={recordAccessFee}
          toggleSelection={() => checkoutSelectedRecords.openWith((studies) => isSelected ? studies.filter(_ => _ !== record) : (studies || []).concat(record))}
          selecting={checkoutSelectedRecords.isOpen}
          hasManyUnpaid={hasManyUnpaid}
          key={record.studyUID}
          jwt={jwt}
          record={record}
        />
      })
    }
  </StudyListTable>
</>
