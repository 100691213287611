import React from 'react'
import {buildValidation, required, responseFailure} from '../../utils/validation'
import {CardActionButton, CardHeader, PurviewFooter, SignInLayout} from 'sharecomponents/src/components/SigninLayout'
import {Errors, TextInput, WithForm} from '@startlibs/form'
import {formFetch} from '@startlibs/utils'
import {SignInCard, WelcomeMessage} from '../../components/StyledComponents'

const DEFAULT_VALUES = process.env.NODE_ENV === 'development' ? {"login":"admin", "password":"Senha!@34"} : {}

const ACCESS_ERROR = "Invalid admin data. Please check the fields above and try again."

export const ProviderLogin = ({providerInfo, navigate, setJwt}) => {

  const preValidation = buildValidation({
    login: [required],
    password: [required]
  })

  // useClearSession()

  const onSuccess = (req, data) => {
    setJwt(data.jwt)
    navigate('/admin')
  }

  const onFailure = responseFailure((data, {status}) => status === 401 && {
    "login": ACCESS_ERROR,
    "password": ACCESS_ERROR
  })


  return <SignInLayout providerInfo={providerInfo}>
    <SignInCard>
      <CardHeader>
          <WelcomeMessage>Patient Access Admin</WelcomeMessage>
      </CardHeader>
      <WithForm
        alwaysSave
        preValidation={preValidation}
        onSuccess={onSuccess}
        onFailure={onFailure}
        values={DEFAULT_VALUES}
        action={formFetch(`/api/v2/provider/${providerInfo.id}/authenticate`)}
      >{form => <>
        <TextInput
          label="Username"
          path="login"
        />
        <TextInput
          label="Password"
          path="password"
          type="password"
        />
        <Errors/>
        <CardActionButton highlight isLoading={form.isLoading} type="submit">Access Patient Access Admin</CardActionButton>
        <PurviewFooter/>
      </>}</WithForm>
    </SignInCard>
  </SignInLayout>
}
