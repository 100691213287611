import React from 'react'
import {CardActionButton, CardHeader, Card, SignInLayout, PurviewFooter} from 'sharecomponents/src/components/SigninLayout'
import {WithForm, TextInput, Errors, DatePicker} from '@startlibs/form'
import _ from 'lodash/fp'
import {sha256} from 'js-sha256'
import styled from 'styled-components'
import {formatDate, formFetch} from '@startlibs/utils'
import {useClearSession} from 'sharecomponents/src/hooks/useClearSession'
import {buildValidation, required, responseFailure} from '../utils/validation'
import {DATE_FORMAT} from '../config/DateFormat'
import {getColor} from '@startlibs/utils'
import {FeeDetails, SignInCard, SignInInstruction, WelcomeMessage} from '../components/StyledComponents'


const transformDoB = _.update('dob',(dob) => {
  return formatDate(dob,'yyyy/MM/dd').replace(/\//g,'')
})

// const DEFAULT_VALUES = {dob: new Date('1977-08-18').getTime(), lastName: 'FVP', patientID: '0001'}

const DEFAULT_VALUES = process.env.NODE_ENV === 'development'
  // ? {dob: new Date('1977-08-18').getTime(), lastName: 'FVP', patientID: '0001'}
  // ? {dob: new Date('2001-01-01').getTime(), lastName: 'JOHN', patientID: '987654'}
  ? {dob: new Date('1980-06-12').getTime(), lastName: 'ademar', patientID: 'ademar'}
  : {dob: undefined, lastName: '', patientID: new URLSearchParams(window.location.search).get("accessCode") || ''}

export const ACCESS_ERROR = "Invalid patient data. Please check the fields above and try again."


export const Login = ({providerInfo,navigate,location}) => {

  const checkLastName = providerInfo.phiProtectionImplemented && providerInfo.phiProtectionAttributes.lastName
  const checkDob = providerInfo.phiProtectionImplemented && providerInfo.phiProtectionAttributes.dob

  const preValidation = buildValidation({
    patientID: [required]
  })

  useClearSession()

  const onSuccess = (req,data) => {
    window.sessionStorage.setItem('jwt',data.jwt)
    window.sessionStorage.setItem('patientID',req.patientID)
    window.sessionStorage.setItem('patientName',data.patientName)
    const hash = sha256([req.patientID,req.lastName,req.dob].join(";"))
    const acceptedTermsKey ="accepted-"+hash
    window.sessionStorage.setItem('acceptedTermsKey', acceptedTermsKey)
    navigate('/studies')
  }

  const onFailure = responseFailure((data,{status})=> status === 401 && {"lastName":ACCESS_ERROR,"patientID":ACCESS_ERROR,"dob":ACCESS_ERROR})

  const dateFormat = DATE_FORMAT(providerInfo.dateFormat)

  return <SignInLayout providerInfo={providerInfo}>
      <WelcomeMessage css="max-width: 34rem;">Welcome to the easiest way to view, download and share your medical images{providerInfo.hasReport && " and reports"}!</WelcomeMessage>
      <SignInCard>
        <CardHeader>
          <SignInInstruction css="font-size: 14px;">
            To access the records please enter the requested information below.
          </SignInInstruction>
        </CardHeader>
        <WithForm
          alwaysSave
          transform={transformDoB}
          preValidation={preValidation}
          onSuccess={onSuccess}
          onFailure={onFailure}
          values={DEFAULT_VALUES}
          action={formFetch('/api/v2/authenticate')}
        >{form =><>
          <TextInput
            label="Access code"
            placeholder="Ex: 00000000"
            path="patientID"
            disabled={DEFAULT_VALUES.patientID}
          />
          {
            checkLastName &&
            <TextInput
              label="Patient last name"
              placeholder="Ex: Smith"
              path="lastName"
            />
          }
          {
            checkDob &&
            <DatePicker
              weekStartsMonday
              label="Patient date of birth"
              placeholder={`Ex: ${dateFormat.toUpperCase()}`}
              format={dateFormat}
              path="dob"
            />
          }
          {providerInfo.recordAccessFee > 0 && <FeeDetails css="max-width: 28rem;">You will be able to purchase permanent access to your images{providerInfo.hasReport && " and reports"} for a one-time charge per scan.</FeeDetails>}
          <Errors />
          <CardActionButton highlight isLoading={form.isLoading} type="submit">Access Records</CardActionButton>
          <PurviewFooter/>
        </>}</WithForm>
      </SignInCard>
    </SignInLayout>
}
