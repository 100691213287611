import parser from 'ua-parser-js'
import {isTouchDevice} from '@startlibs/utils'

export const tryOrFail = (test,fail) => {
  try {
    return test()
  } catch(e) {
    fail()
  }
}

export const getDateValue = (key,params) => params[key] ? new Date(params[key]) : NaN

export const searchToParams = (search) => {
  const urlParams = new URLSearchParams(search).entries()
  const result = {}
  for (var pair of urlParams) {
    result[pair[0]] = pair[1]
  }
  return {
    studyStatus: '',
    ...result
  }
}

export const isMac = parser().os.name === "Mac OS" && !isTouchDevice()
export const isIOs = (parser().os.name === "Mac OS" && isTouchDevice()) || (parser().os.name === "iOS")

export const strToDate = (str) => str ? new Date(str.replace(/\s.*/, "")) : new Date(str)


export const getDate = (n) => {
  const date = new Date()
  date.setDate(date.getDate() + n)
  return date
}

export const getDateYear = (n) => {
  const date = new Date()
  date.setFullYear(date.getFullYear() + n)
  return date
}

export const isUsingPastYear = (params) =>
  params.pastYear !== "false" && !params.uploadDateInit && !params.uploadDateEnd

export const isPastYearToggleDisabled = (params) => params.uploadDateInit || params.uploadDateEnd