import {ContextMenu, Icon, Li, Loading, Tooltip} from '@startlibs/components';
import {Link} from '@reach/router'
import { ToggleCheckbox, RadioboxGroup, TextInput } from '@startlibs/form';
import {
  addQueryString,
  callIfFunction,
  getColor,
  media,
  isMobile,
  smoothScroll, formatDate
} from '@startlibs/utils';
import {useConstant, Slot, usePopupToggle, useToggle, Fill, useRefState} from '@startlibs/core'
import React, { Suspense, useEffect, useState } from 'react';
import _ from 'lodash/fp'
import styled, {css} from 'styled-components';

import {ActiveAdvancedFilters} from './patientList/ActiveAdvancedFilters'
import {AdvancedSearchPopup} from './patientList/AdvancedSearchPopup'
import {FetchErrorDialog} from './errors/FetchErrorDialog'
import { Navbar, NavbarSystemLogo } from '../RecordList';
import {Pagination} from '../../components/Pagination'
import {PurviewFooter} from '../../components/PurviewFooter'
import { SectionHeading } from '../../components/PageLayout';
import {Table} from './patientList/Table';
import {callUpdateParams, updateParams, useUpdateParams} from '../../hooks/useUpdateParams'
import {jwtGetFetcher} from '../../utils/authFetch'
import {getDate, getDateYear, isPastYearToggleDisabled, isUsingPastYear, searchToParams} from '../../utils/utils';
import {cachedWorklistFetcher} from './utils/cachedWorklistFetcher'
import {TextButton} from '../../components/StyledComponents'

const PatientListPageContainer = styled.div `
  padding: 8rem 1.5rem 90px;
  margin: 0 auto;
  position: relative;
  min-width: 300px;
  max-width: 90rem;
  min-height: 100vh;
  p {
    font-size: 13px;
  }
  ${media.max(799)`
    max-width: auto;
    padding-left: 1rem;
    padding-right: 1rem;
  `}
  ${media.max(520)`
    padding-top: 6.25rem;
  `}
`

const PatientListCard = styled.div`
  & ~ ${SectionHeading} {
    margin-top: 3rem;
  }
  padding: 2.5rem;
  background: white;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  margin: 0 auto;
  ${media.max(520)`
    padding: 1.5rem;
  `}
`

const PastYearCheckbox = styled(ToggleCheckbox)`
  margin-bottom: 0;
  margin-left: 1rem;
  white-space: nowrap;
  min-width: 225px;
  ${props => props.disabled && css `
    color: ${getColor('gray150')};
  `}
  ${media.max(601)`
    padding: 13px 1rem;
  `}
`


const PatientListHeaderComponent = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
  z-index: 30;
  .left-wrapper {
    min-width: 300px;
    flex-grow: 1;
  }
  ${media.max(625)`
    display: block;
    .right-wrapper {
      padding-top: 1rem;
      width: 100%;
      > div {
        width: 100%;
        label {
          margin-left: 0;
        }
      }
    }
  `}
`

const LinkButton = styled.a`
  position: relative;
  display: inline-block;
  text-decoration: underline;
  user-select: none;
`

const SearchInputWrapper = styled.div`
  display: flex;
  align-items: center;
  color: ${getColor('gray120')};
  ${media.max(450)`
    display: block;
  `}
`
const SearchInput = styled.div`
  position: relative;
  display: inline-block;
  margin-right: 1rem;
  max-width: 30rem;
  width: 100%;
  ${media.max(450)`
    margin-right: 0;
    margin-bottom: .5rem;
    max-width: 100%;
  `}
  ${LinkButton} {
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
  }
  > input {
    padding-right: 8.75rem;
  }
  ${props => props.expandedInput && `
    > input {
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
    }
  `}
`
const ClearSearchButton = styled(Icon)`
  color: ${getColor('gray90')};
  font-size: 12px;
  position: absolute;
  right: 80px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  width: 1.5rem;
  line-height: 1.5rem;
  text-align: center;
  border-radius: 50%;
  background: rgba(0,0,0,0.1);
  :hover {
    background: #f7d3d4;
    color: ${getColor('alert')};
  }
`

const PaginationContainer = styled.div`
  text-align: center;
  margin-top: 2rem;
`

const MaxResultsMenu = styled(ContextMenu)`
  li > * {
    padding: 11px 2rem;
  }
`
const LoadingWorklist = styled.div`
  border-radius: 5px;
  height: 26rem;
`

export const PatientList = ({providerInfo,jwt,navigate,location}) => {

  if (!jwt) {
    navigate('login')
    return null
  }

  const loading = useToggle()
  const [params, rawSetParams] = useState(() => searchToParams(location.search))

  const updateParams = (params) => {
    rawSetParams(params)
    callUpdateParams(params)
  }

  useEffect(() => {
    updateParams(searchToParams(location.search))
  },[location])


  const debounceSetParams = useConstant(_.debounce(200, rawSetParams))

  const setParams = (updater, debounce) => {
    const verifyPage = (updater) => (state) => {
      const updatedState = callIfFunction(updater,state)
      if (
        state.search !== updatedState.search ||
        state.dateOfBirth !== updatedState.dateOfBirth ||
        state.studyDate !== updatedState.studyDate ||
        state.uploadDateInit !== updatedState.uploadDateInit ||
        state.uploadDateInit !== updatedState.uploadDateInit
      ) {
        return _.set('page', 1, updatedState)
      } else if (state.maxResults !== updatedState.maxResults) {
        const firstRow = ((state.maxResults||10) * (updatedState.page-1)+1)
        return _.set('page',Math.max(Math.floor(firstRow/(updatedState.maxResults||10))+1,1),updatedState)
      } else {
        return updatedState
      }
    }
    if (debounce) {
      debounceSetParams(verifyPage(updater))
    } else {
      setTimeout(() => rawSetParams(verifyPage(updater)),0)
    }
    setTimeout(loading.open,0)
  }

  return <>
    <Navbar>
      <NavbarSystemLogo>
        <img src={providerInfo.logoURL} alt="Logo"/>
        <span>Patient Access Admin</span>
      </NavbarSystemLogo>
      <TextButton as={Link} to="/">Logout</TextButton>
    </Navbar>
    <PatientListPageContainer isMobile={isMobile()}>
      <SectionHeading>
        <h3>Patient medical records</h3>
      </SectionHeading>
      <PatientListCard>
        <WorklistHeader params={params} setParams={setParams} location={location} loading={loading}/>
        <Suspense fallback={null}>
          <PatientTable
            jwt={jwt}
            providerInfo={providerInfo}
            updateParams={updateParams}
            loading={loading} params={params} setParams={setParams} location={location}
          />
        </Suspense>
      </PatientListCard>
    </PatientListPageContainer>
    <PurviewFooter/>
  </>
}

const WorklistHeader = ({location, loading, params, setParams}) => {

  const query = useToggle(params.search || '')
  const maxResults = useToggle(params.maxResults || '')

  const updateUrl = useConstant(
    _.throttle(200, (obj) => {
      window.top.history.pushState('','',('?'+addQueryString('', _.pickBy(_.identity,obj))).replace(/\?\?/, '?'))
    })
  )

  useUpdateParams((params) => {
    query.openWith(params.search || "")
    maxResults.openWith(params.maxResults || "")
  })

  useEffect(() => {
    updateUrl(params)
  }, [params])


  const maxResultsMenu = usePopupToggle()
  const advancedPopup = useToggle()

  const updateSearch = (v) => {
    query.openWith(v)
    setParams(_.set('search', v), true)
  }

  const updateMaxResult = (v) => {
    maxResults.openWith(v)
    setParams(_.set('maxResults', v), true)
  }

  return <PatientListHeaderComponent>
    <div className="left-wrapper">
      <SearchInputWrapper>
        <SearchInput expandedInput={advancedPopup.isOpen}>
          <TextInput raw value={query.isOpen} setValue={updateSearch} placeholder="Search"/>
          {query.isOpen && <ClearSearchButton icon="x" onClick={() => updateSearch("")}/>}
          <LinkButton onClick={advancedPopup.open}>
            Advanced
          </LinkButton>
          {
            advancedPopup.isOpen &&
            <AdvancedSearchPopup
              focusedInput={advancedPopup.isOpen}
              closePopup={advancedPopup.close}
              setParams={setParams}
              params={params}
            />
          }
        </SearchInput>
        <div>
          <Slot name="QueryCount"/>
          <div className="nowrap">Showing <LinkButton onClick={maxResultsMenu.open}>{(maxResults.isOpen || 10)} per page
            {
              maxResultsMenu.isOpen &&
              <MaxResultsMenu offset="0 4">
                <Li label="10" onClick={() => updateMaxResult('')}/>
                <Li label="25" onClick={() => updateMaxResult(25)}/>
                <Li label="50" onClick={() => updateMaxResult(50)}/>
              </MaxResultsMenu>
            }
          </LinkButton>
          </div>
        </div>
      </SearchInputWrapper>
      <ActiveAdvancedFilters params={params} setParams={setParams} advancedPopup={advancedPopup} location={location}/>
    </div>
    <div className="right-wrapper">
      <PastYearToggle params={params} setParams={setParams}/>
    </div>
  </PatientListHeaderComponent>
}

const PastYearToggle = ({params,setParams}) => {
  const value = isUsingPastYear(params)
  const isDisabled = isPastYearToggleDisabled(params)
  const setValue = (v) => setParams(_.set('pastYear', v+""))


  const toggle = <PastYearCheckbox
    raw
    label="Display last 12 months only"
    value={value}
    disabled={isDisabled}
    setValue={setValue}
  />

  return isDisabled ? <Tooltip content="This limit is disabled while filtering.">{toggle}</Tooltip> : <Tooltip content="Pages load faster if enabled.">{toggle}</Tooltip>
}


const PatientTable = React.memo(({params, jwt, updateParams, loading, setParams, providerInfo, ...props}) => {

  const [worklist, setWorklist] = useState()
  const lastParams = useRefState(params)
  const lastSuccessParams = useRefState(params)
  const errorDialog = useToggle()

  const maxResults = Number(params.maxResults) || 10
  const currentPage = Number(params.page) || 1

  const loadList = (currentParams,closeLoading,refreshing) => {
    lastParams.set(currentParams)
    if (lastParams.get() === lastSuccessParams.get() && !refreshing) {
      callIfFunction(closeLoading)
      return Promise.resolve()
    }
    const uploadDateInit = formatDate(getDateYear(-1),"yyyy-MM-dd")
    const uploadDateEnd = formatDate(getDate(0),"yyyy-MM-dd")
    const filteringYearDates = isUsingPastYear(params) ? {uploadDateEnd,uploadDateInit} : {}
    return cachedWorklistFetcher(providerInfo,jwt,{page: 1, maxResults: 10, ...filteringYearDates, ..._.pickBy(_.identity,currentParams)},refreshing)
    // return jwtGetFetcher(jwt)(addQueryString(`/api/v2/provider/${providerInfo.id}/patients`, {maxResults: 10, page: 1, ..._.pickBy(_.identity,currentParams)}))
      .then((worklist) => {
          if (lastParams.get() === currentParams) {
            lastSuccessParams.set(currentParams)
            window.scroll(0, window.pageYOffset);
            smoothScroll(0)
            setWorklist({worklist, key: Date.now()})
            callIfFunction(closeLoading)
          }
        }
      )
      .catch(() => {
        if (lastParams.get() === currentParams) {
          callIfFunction(closeLoading)
          errorDialog.open()
          updateParams(lastSuccessParams.get())
        }
      })
  }

  const refreshList = () => loadList(params,null,true)

  useEffect(() => {
    loading.open()
    loadList(params,loading.close)
  }, [params])



  if (!worklist) {
    return <>
      <Fill name="QueryCount"><b>Loading...</b></Fill>
      <LoadingWorklist><Loading size={40} borderWidth={6} absolute/></LoadingWorklist>
    </>
  }
  const {worklist: {total, patients}, key} = worklist

  return <>
    <Fill name="QueryCount"><b>{loading.isOpen ? 'Loading...' : <>{total} entries{params.search && " found"}</>}</b></Fill>
    <Fill name="Pagination"><Pagination setPageParam={(page) => () => setParams(_.set('page',page))}
                                        currentPage={currentPage}
                                        totalPages={Math.ceil((Number(total)) / maxResults)}/></Fill>
    <Table providerInfo={providerInfo} key={key} jwt={jwt} refreshList={refreshList} isLoading={loading.isOpen} setParams={setParams} {...props} queryCount={Number(total)} rows={patients}/>
    {
      errorDialog.isOpen &&
      <FetchErrorDialog closeDialog={errorDialog.close} />
    }
    <PaginationContainer><Slot name="Pagination"/></PaginationContainer>
  </>
})
