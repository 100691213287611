import styled from 'styled-components';
import { getColor, media } from '@startlibs/utils';
import React from 'react'
import _ from 'lodash/fp'

import {ShareDialog} from 'sharecomponents/src/pages/ShareDialog'
import {jwtPostFetcher} from '../../utils/authFetch'

export const ExamCardStyles = styled.div`
  h3 {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: .5rem;
    margin-top: .25rem;
  }
  .card-footer {
    padding: 0.5rem 1rem;
    margin: 1rem -1rem -0.75rem -1rem;
    background: rgba(0,0,0,0.075);
    color: ${getColor('gray120')};
    border-top: 1px solid ${getColor('gray210')};
    font-size: 11px;
  }
`

//{study.link}
export const PatientShareDialog = ({jwt, patient,closeDialog,shareableLinkIsOpen, providerInfo}) =>
  <ShareDialog
    closeDialog={closeDialog}
    shareableLinkIsOpen={shareableLinkIsOpen}
    authorize={() => Promise.resolve(jwt)}
    infoCard={() => <ExamCardStyles>
      <h3>Patient name: {patient.name}</h3>
      <div className="study-info"><strong>Date of birth:</strong> {patient.dob}</div>
      <div className="card-footer">
        <strong>Access code:</strong> {patient.accessCode}
      </div>
    </ExamCardStyles>}
    labels={{
      firstStepTitle:(byLink) => "Share access link" + (byLink ? "" : " by email"),
      firstStepSubTitle: <span>You are about to share <strong>all exams</strong> from the following patient:</span>,
      secondStepSubTitle:"Please review the details before sharing:",
      additionalInfo: "Confirmation data will be requested upon accessing the link."

    }}
    defaultFormValues={{emails:[]}}
    transform={_.identity}
    authorizationIsOpen={jwt}
    shareFetch={(emails) => jwtPostFetcher(jwt)(`/api/v2/provider/${providerInfo.id}/patients/${patient.id}/email`,emails)}
    getLink={() => Promise.resolve({url:patient.link})}
    jwt={jwt}
  />
