import {AutoComplete, DatePicker, Field, WithForm} from '@startlibs/form'
import {Button, Dialog, Icon, Link, SplitColumnsContainer} from '@startlibs/components'
import {
  _s,
  addQueryString,
  formatDate,
  getColor,
  media
} from '@startlibs/utils';
import {useLazyConstant, useToggle} from '@startlibs/core'
import React, {useEffect, useRef} from 'react'
import _ from 'lodash/fp'
import styled from 'styled-components'

import {DATE_FORMAT} from '../../../config/DateFormat'
import {getDate, getDateValue, getDateYear} from '../../../utils/utils'

const AdvancedPopupComponent = styled.div `
  position: absolute;
  background-color: #FFF;
  border: 1px solid ${getColor('gray210')};
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 1.25rem;
  z-index: 999;
  left: 0;
  width: 100%;
  margin-top: -1px;
  .upload-dateshortcuts {
    margin-top: -0.25rem;
    margin-bottom: 1rem;
    a {
      color: ${getColor('gray150')};
      margin-right: 1rem;
      text-decoration: underline;
    }
  }
  .popup-buttons-container {
    text-align: right;
  }
  .close-icon {
    color: ${getColor('gray150')};
    font-size: 20px;
    position: absolute;
    top: 6px;
    right: 5px;
    cursor: pointer;
    width: 2rem;
    line-height: 2rem;
    text-align: center;
    border-radius: 50%;
    :hover {
      background: ${getColor('gray240')};
    }
  }
`
const validateTag = (v) => ({errors:true})
export const AdvancedSearchPopup = ({params, focusedInput, setParams,closePopup}) => {
  const popupRef = React.useRef()

  const dateOfBirth = useToggle(getDateValue('dateOfBirth',params))
  const studyDate = useToggle(getDateValue('studyDate',params))
  const uploadDateInit = useToggle(getDateValue('uploadDateInit',params))
  const uploadDateEnd = useToggle(getDateValue('uploadDateEnd',params))
  const updateFields = useToggle()
  const focusRef = useRef()
  const formRef = useRef()

  useEffect(() => {
    const tryClose = (e) => !(popupRef.current.contains(e.target) || e.target === popupRef.current || e.defaultPrevented) && closePopup()
    document.addEventListener("click",tryClose)
    return () => document.removeEventListener("click",tryClose)
  },[])

  useEffect(() => {
    if (focusRef.current) {
      focusRef.current.focus()
    }
  },[])

  const confirmAdvancedSearch = () => {
    setParams(_s.assign({
      dateOfBirth:_.isNumber(dateOfBirth.isOpen) || _.isDate(dateOfBirth.isOpen) ? formatDate(dateOfBirth.isOpen,"yyyy-MM-dd") : "",
      studyDate:_.isNumber(studyDate.isOpen) || _.isDate(studyDate.isOpen)  ? formatDate(studyDate.isOpen,"yyyy-MM-dd") : "",
      uploadDateInit: _.isNumber(uploadDateInit.isOpen) || _.isDate(uploadDateInit.isOpen) ? formatDate(uploadDateInit.isOpen, "yyyy-MM-dd") : "",
      uploadDateEnd: _.isNumber(uploadDateEnd.isOpen) || _.isDate(uploadDateEnd.isOpen) ? formatDate(uploadDateEnd.isOpen, "yyyy-MM-dd") : ""
    }))
    closePopup()
  }
  return <AdvancedPopupComponent ref={popupRef} onKeyDown={({key})=> key === "Enter" && confirmAdvancedSearch()} onClick={(e) =>e.stopPropagation()}>
    <DatePicker ref={focusedInput === "dateOfBirth" ? focusRef : undefined} label="Patient date of birth" placeholder={DATE_FORMAT().toUpperCase()} format={DATE_FORMAT()} raw value={dateOfBirth.isOpen} setValue={dateOfBirth.openWith}/>
    <DatePicker ref={focusedInput === "studyDate" ? focusRef : undefined} label="Exam date" placeholder={DATE_FORMAT().toUpperCase()} format={DATE_FORMAT()} raw value={studyDate.isOpen} setValue={studyDate.openWith} />
    <Field label="Upload date between">
      <SplitColumnsContainer key={updateFields.isOpen} viewportMinWidth={465}>
        <DatePicker ref={focusedInput === "uploadDateInit" ? focusRef : undefined} label="" placeholder={DATE_FORMAT().toUpperCase()} format={DATE_FORMAT()} raw value={uploadDateInit.isOpen} setValue={uploadDateInit.openWith} />
        <DatePicker ref={focusedInput === "uploadDateEnd" ? focusRef : undefined} label="" placeholder={DATE_FORMAT().toUpperCase()} format={DATE_FORMAT()} raw value={uploadDateEnd.isOpen} setValue={uploadDateEnd.openWith} />
      </SplitColumnsContainer>
      <div className="upload-dateshortcuts">
        <a onClick={() => {uploadDateInit.openWith(getDate(-30)); uploadDateEnd.openWith(getDate(0)); updateFields.openWith(Date.now())}}>Last 30 days</a>
        <a onClick={() => {uploadDateInit.openWith(getDate(-7)); uploadDateEnd.openWith(getDate(0)); updateFields.openWith(Date.now())}}>Last 7 days</a>
        <a onClick={() => {uploadDateInit.openWith(getDate(0)); uploadDateEnd.openWith(getDate(0)); updateFields.openWith(Date.now())}}>Today</a>
      </div>
    </Field>
    <div className="popup-buttons-container">
      <Button onClick={closePopup} tabIndex={2}>Cancel</Button>
      <Button highlight onClick={confirmAdvancedSearch}>Search</Button>
    </div>
  </AdvancedPopupComponent>
}
