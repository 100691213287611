import { Icon, Tooltip } from '@startlibs/components';
import { formatDate, getColor, media, stopPropagation } from '@startlibs/utils';
import React, {useEffect, useState} from 'react'
import _ from 'lodash/fp'
import styled from 'styled-components'

import {DATE_FORMAT} from '../../../config/DateFormat'
import {getDateValue} from '../../../utils/utils'


const ActiveFilters = styled.div `
  margin-bottom: -1.25rem;
  ${media.max(800) `
    margin-bottom: -0.75rem;
  `}
  ${media.max(625) `
    margin-bottom: 0;
  `}
  margin-top: 0.5rem;
  > span {
    margin-top: 0.25rem;
    background: rgba(0,0,0,0.075);
    border-radius: 50px;
    padding: 0 0.5rem;
    margin-left: 0.25rem;
    line-height: 2rem;
    display: inline-block;
    &.past-year {
      background: #f0f6f8;
      border: 1px solid ${getColor('main')};
      color: ${getColor('main')};
      :hover {
        background: rgba(185,200,210,0.3);
      }
      ${Icon} {
        background: rgba(0,139,210,0.125);
        :hover {
          background: #dec0c1;
          color: ${getColor('alert')};
        }
      }
    }
  }
  > span:hover {
    background: rgba(0,0,0,0.1);
    cursor: pointer;
  }
  ${props => props.popupIsOpen && `
    opacity: 0.4;
    pointer-events: none;
  `}
  ${Icon} {
    background: rgba(0,0,0,0.075);
    border-radius: 50%;
    width: 18px;
    height: 18px;
    line-height: 18px;
    margin: 0 -0.25rem 0 0.25rem;
    text-align: center;
    display: inline-block;
    vertical-align: -1px;
    :hover {
      background: #dec0c1;
      color: ${getColor('alert')};
    }
  }
  a {
    color: inherit;
    text-decoration: none;
  }
  .tag {
    max-width: 120px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    vertical-align: -8px;
  }
`

export const ActiveAdvancedFilters = ({advancedPopup,params,setParams}) => {

  const dateOfBirth = getDateValue('dateOfBirth',params)
  const studyDate = getDateValue('studyDate',params)
  const uploadDateInit = getDateValue('uploadDateInit',params)
  const uploadDateEnd = getDateValue('uploadDateEnd',params)
  const uploadDateSameDay = uploadDateEnd && uploadDateInit && uploadDateEnd.getTime() === uploadDateInit.getTime()

  const hasFilters = dateOfBirth || studyDate || uploadDateInit || uploadDateEnd

  const removeKeys = (...keys) => (e) => {
    keys.forEach(key => {
      setParams(_.unset(key))
    })
    e.stopPropagation()
  }

  if (!hasFilters) return null



  return <ActiveFilters popupIsOpen={advancedPopup.isOpen}>
    <b>Filtering:</b>
    {
      !!dateOfBirth && <span onClick={advancedPopup.willOpenWith("dateOfBirth")}>DOB: {formatDate(dateOfBirth,DATE_FORMAT())}<a onClick={removeKeys("dateOfBirth")}><Icon icon="x"/></a></span>
    }
    {
      !!studyDate && <span onClick={advancedPopup.willOpenWith("studyDate")}>Study date: {formatDate(studyDate,DATE_FORMAT())}<a onClick={removeKeys("studyDate")}><Icon icon="x"/></a></span>
    }
    {
      !uploadDateSameDay && !!uploadDateEnd && !!uploadDateInit && <span onClick={advancedPopup.willOpenWith("uploadDateInit")}>Uploaded between: {formatDate(uploadDateInit,DATE_FORMAT())} and {formatDate(uploadDateEnd,DATE_FORMAT())}<a onClick={removeKeys('uploadDateEnd','uploadDateInit')}><Icon icon="x"/></a></span>
    }
    {
      !!uploadDateSameDay && <span onClick={advancedPopup.willOpenWith("uploadDateInit")}>Uploaded on: {formatDate(uploadDateInit,DATE_FORMAT())} <a onClick={removeKeys('uploadDateInit', 'uploadDateEnd')}><Icon icon="x"/></a></span>
    }
    {
      !uploadDateEnd && !!uploadDateInit && <span onClick={advancedPopup.willOpenWith("uploadDateInit")}>Uploaded after: {formatDate(uploadDateInit,DATE_FORMAT())} <a onClick={removeKeys('uploadDateInit')}><Icon icon="x"/></a></span>
    }
    {
      !!uploadDateEnd && !uploadDateInit &&
      <span onClick={advancedPopup.willOpenWith("uploadDateEnd")}>Uploaded before: {formatDate(uploadDateEnd,DATE_FORMAT())}<a onClick={removeKeys('uploadDateEnd')}><Icon icon="x"/></a></span>
    }
  </ActiveFilters>
}
