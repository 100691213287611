import {Button, SplitColumnsContainer, Dialog, Icon} from '@startlibs/components'
import {Field, WithForm, Errors, TextInput, SimpleCheckbox} from '@startlibs/form'
import { callIfFunction, formatDate, getColor, media } from '@startlibs/utils';
import { useRefState, useToggle } from '@startlibs/core';
import React, { useEffect, useState } from 'react';
import _ from 'lodash/fp'
import load from 'little-loader'
import styled, {css} from 'styled-components'

import {postFetcherWithBearer} from 'sharecomponents/src/utils/authFetch'

import { AcceptTermsDialog } from '../recordList/AcceptTermsDialog';
import { Counters, Modality } from '../recordList/RecordRow';
import {CreditCardIcon, PaymentDetails, StripeInput} from './CheckoutComponents'
import {STRIPE_KEY} from '../../config/StripeConfig'
import {buildValidation, emailValidation, required, responseFailure} from '../../utils/validation'
import {formatCurrency} from '../../utils'
import {getRecordID, STUDY_UID} from '../../enums/RecordType';

const CheckoutDialog = styled(Dialog)`
  max-width: 49rem;
`

const preValidation = buildValidation({
    acceptedTerms: (v) => !v && 'You must read and agree to our License Terms',
    email: emailValidation,
    cardholder: required
  }
)

const onFailure = responseFailure(({status,errors}) =>
  !!errors || ({'': ['Something went wrong. Please try again. If the error persists please contact us.']})
)

export const CreditCardCheckout = ({closeDialog, acceptedTerms, providerInfo, studies, recordAccessFee, selectMore, jwt, payStudies, cancelSelection, stripeKey = STRIPE_KEY()}) => {

  const [cardBrand, setCardBrand] = useState('unknown')
  const [stripe, setStripe] = useState()

  const [cachedStudies] = useState(studies)

  const cardRef = useRefState()
  const cardErrorsRef = useRefState({
    cardCvc: {message: 'Your card\'s security code is incomplete.'},
    cardExpiry: {message: 'Your card\'s expiration date is incomplete.'},
    card: {message: 'Your card number is incomplete.'}
  })
  const cardModifiedRef = useRefState()

  const successDialog = useToggle()

  const loadStripe = () =>
    window.Stripe ? Promise.resolve(window.Stripe) : new Promise((res, rej) =>
      load('https://js.stripe.com/v3/', (err) => {
        if (err) { rej() }
        res(window.Stripe)
      })
    )


  const initStripe = (stripe) => {
    const elements = stripe.elements()
    const style = {
      base: {
        color: '#3c3c3c',
        lineHeight: '18px',
        fontFamily: '"Open Sans", sans-serif',
        fontSize: '14px',
        '::placeholder': {
          color: 'rgba(0,0,0,0.35)'
        }
      },
      invalid: {
        color: '#C3282D',
        iconColor: '#fa755a'
      }
    }

    const cardExpiry = elements.create('cardExpiry', {style})
    cardExpiry.mount('#card-expiry-element')
    cardExpiry.on('change', ({error}) => {
      cardModifiedRef.set(true)
      cardErrorsRef.set((cardErrors) => ({...cardErrors, cardExpiry: error}))
    })

    const cardCvc = elements.create('cardCvc', {style})
    cardCvc.mount('#card-cvc-element')
    cardCvc.on('change', ({error}) => {
      cardModifiedRef.set(true)
      cardErrorsRef.set((cardErrors) => ({...cardErrors, cardCvc: error}))
    })

    cardRef.set(elements.create('cardNumber', {style}))
    cardRef.get().on('change', (event) => {
      setCardBrand(event.brand)
      cardModifiedRef.set(true)
      cardErrorsRef.set((cardErrors) => ({...cardErrors, card: event.error}))
    }).on('ready',(() => {
      setStripe(stripe)
    }))
    cardRef.get().mount('#card-element')
  }

  useEffect(() => {
    loadStripe().then(Stripe => {
        initStripe(Stripe(stripeKey))
      }
    )
  }, [])


  const termsDialog = useToggle()

  const submitSubscriptionUpdate = (values) => {
    /*const {loading, nextStep, , orderDetails} = this.props
    const [[coupon,couponError]] = orderDetails*/

    const errors = preValidation(values, {})
    const cardErrors = _.entries(cardErrorsRef.get()).filter(([k, v]) => v).map(([k, v]) => [v.message])
    const stripeValidation = Object.keys(cardErrors).length ?
                             stripe.createToken(cardRef.get()).then((result) => ({card: [result.error.message]})) :
                             Promise.resolve({})

    if (errors) {
      return stripeValidation
        .then((stripeErrors) => Promise.reject([{errors: {...errors, ...cardErrors}}]))
    }

    return stripe.createToken(cardRef.get(), {
      address_zip: values.zipCode,
      address_country: values.country,
      name: values.cardholder
    }).then((stripeResult) => {
      if (stripeResult.error) {
        // Inform the customer that there was an error.
        if (stripeResult.error) {
          throw [{errors: {card: [stripeResult.error.message]}}]
        } else {
          throw [{errors: {}}]
        }
      } else {
        return postFetcherWithBearer(jwt)('/api/v2/records/pay/', {
          stripeToken: stripeResult.token.id,
          recordUIDS: studies.map(getRecordID),
          email: values.email
        })
      }
    })
  }

  if (successDialog.isOpen) {
    return <Dialog
      closeDialog={closeDialog}
      title="Payment successful"
      footer={<>
        <Button highlight onClick={closeDialog}>Close</Button>
      </>}
    >
      <p>Your payment of <b>{providerInfo.currency.symbol}{formatCurrency(recordAccessFee * cachedStudies.length)} {providerInfo.currency.code.toUpperCase()}</b> was processed successfully. You can now access all the available features for the following records:</p>
      <OrderDetails studies={cachedStudies} paid providerInfo={providerInfo}/>
      <PaymentDetails>
        <h3>Your receipt has been sent.</h3>
        <p>A receipt of this purchase has been sent to <b>{successDialog.isOpen}</b>.</p>
      </PaymentDetails>
    </Dialog>
  }

  return <>
    <WithForm
    alwaysSave
    values={{acceptedTerms}}
    onFailure={onFailure}
    action={submitSubscriptionUpdate}
    onSuccess={({email}) => {
      payStudies(studies)
      callIfFunction(cancelSelection)
      successDialog.openWith(email)
    }}
  >{form =>
    <CheckoutDialog
      form={form}
      title="Insert payment details"
      closeDialog={closeDialog}
      footer={<>
        <Button onClick={closeDialog} disabled={form.isLoading} tabIndex={2}>Cancel</Button>
        <Button type="submit" disabled={!stripe} isLoading={form.isLoading} highlight>Purchase now</Button>
      </>}
    >
      <p>You are about to pay <b>{providerInfo.currency.symbol}{formatCurrency(recordAccessFee * studies.length)} {providerInfo.currency.code.toUpperCase()}</b> to unlock the following records, making them permanently available.</p>
      <OrderDetails studies={studies} selectMore={selectMore} providerInfo={providerInfo}/>
      <PaymentDetails>
            <h3>Payment details</h3>
            <p>To confirm your purchase, please fill in your payment details below:</p>
            <TextInput
              autoFocus
              type="text"
              path="email"
              label="Email:"
              tabIndex={0}
              placeholder="Ex: johndoe@email.com"
              helpText="(we will send you a receipt once the purchase is completed)"
            />
          <SplitColumnsContainer viewportMinWidth={500}>
              <Field label="Card number:">
                <StripeInput id="card-element"/>
                <CreditCardIcon src={`/public/icon/${cardBrand}.svg`}/>
              </Field>
              <TextInput
                type="text"
                path="cardholder"
                label="Name on card:"
                placeholder="Ex: John Doe"
                tabIndex={0}
              />
            </SplitColumnsContainer>
            <SplitColumnsContainer viewportMinWidth={500}>
              <Field label="Expiration:">
                <StripeInput id="card-expiry-element"/>
              </Field>
              <Field label="CVC:">
                <StripeInput id="card-cvc-element"/>
              </Field>
            </SplitColumnsContainer>
            <SimpleCheckbox
              path="acceptedTerms"
              framedBoxes
              label={<span>I confirm and I accept the <a className="link nowrap" target="_blank" onClick={e => {e.preventDefault(); e.stopPropagation(); termsDialog.open()}}>License Terms</a>.</span>}
            />
      </PaymentDetails>
      <Errors/>
    </CheckoutDialog>
  }</WithForm>
    {
      termsDialog.isOpen && <AcceptTermsDialog
        closeDialog={termsDialog.close}
        providerInfo={providerInfo}
        jwt={jwt}
      />
    }
    </>
}

const OrderDetailsBox = styled.div `
  border-radius: 6px;
  border: 1px solid rgba(0,0,0,0.25);
  margin-bottom: 2rem;
  ${media.min(500)`
    display: flex;
  `}
`
const RecordsListContainer = styled.div `
  color: rgba(0,0,0,0.7);
  flex-grow: 1;
  padding: 1rem;
  display: flex;
  align-items: center;
  position: relative;
  .divider {
    margin: 0 .25rem;
  }
  .showids {
    color: ${getColor('gray150')};
    cursor: pointer;
    text-decoration: underline;
    display: inline-block;
  }
  ul {
    font-size: 13px;
    margin-bottom: 0.25rem;
    li {
      padding-left: 0.75rem;
      position: relative;
      ~ li {
        margin-top: .5rem;
      }
      :before {
        content:"•";
        position: absolute;
        left: 0;
        top: 0;
        height: 18px;
      }
      .studyuid {
        font-size: 10px;
        color: ${getColor('gray150')};
        display: none;
        ${props => props.viewingIds.isOpen && css`
            display: block;
        `}
      }
    }
  }
`
const FeaturesContainer = styled.div `
  background: ${getColor('gray240')};
  color: ${props => props.paid ? getColor('main') : getColor('gray90')};
  padding: 1rem 1.25rem 1rem 1.75rem;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  position: relative;
  font-size: 11px;
  display: flex;
  align-items: center;
  ${Icon} {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(-50%,-50%);
    width: 2rem;
    line-height: 2rem;
    text-align: center;
    color: white;
    background:${props => props.paid ? getColor('main') : getColor('gray180')};
    border-radius: 50%;
    font-size: ${props => props.paid ? "18px" : "15px"};
  }
  .feature {
    margin-top: 0.5rem;
    padding: 0.25rem 0.75rem;
    background: white;
    border-radius: 5px;
    display: inline-block;
    font-weight: 600;
    & ~ .feature {
      margin-left: 0.5rem;
    }
  }
  ${media.max(499)`
    border-top-right-radius: 0px;
    border-bottom-left-radius: 6px;
    padding-left: 1rem;
    ${Icon} {
      display: none;
    }
  `}
`

const OrderDetails = ({recordAccessFee, studies, selectMore, paid, providerInfo}) => {
  const viewingIds = useToggle()
  const isStudy = providerInfo.recordType === STUDY_UID

  const getReportQuantity = (record) => (record.report ? 1 : 0) + record.studies.filter(({report})=>report).length
  return <OrderDetailsBox>
    <RecordsListContainer viewingIds={viewingIds}>
      <div>
        <ul>
          {studies.map(record =>
            isStudy
            ? <li key={record.studyUID}>
            <div>{record.fullDescription}</div>
            {viewingIds.isOpen && <div className="studyuid">{record.studyUID}</div>}
          </li>
              : <li key={record.accessionNumber}>
                {record.accessionNumber ?
                  <strong>Exam: {record.accessionNumber}</strong>
                  :
                  <strong>Exam date: {record.formattedDate}</strong>
                }
              <Counters>
                <div><Icon icon="dicom-no-text"/> {record.studies.length} scan{record.studies.length !== 1 && 's'}</div>
                {_.uniq(record.studies.map(_.get("modality"))).map(modality => <Modality>{modality}</Modality>)}
              </Counters>
              </li>
          )}
        </ul>
        {selectMore && <span>
          <a className="link select-more" onClick={selectMore}>Select more records</a>
          {isStudy && <span className="divider">-</span>}
        </span>}
        {
          isStudy &&
          <span className="showids" onClick={viewingIds.toggle}>
            {viewingIds.isOpen ? 'Hide' : 'View'} IDs
          </span>
        }
      </div>
    </RecordsListContainer>
    <FeaturesContainer paid={paid}>
      <Icon icon={paid ? "check" : "unlock"}/>
      <div>
      <div>{paid ? "Features now available:" : "All features will be unlocked:"}</div>
      <span className="feature">Download</span><span className="feature">View</span><span className="feature">Share</span>
      </div>
    </FeaturesContainer>
  </OrderDetailsBox>
}
