import React from 'react'
import styled, {css} from 'styled-components'
import {getColor} from '@startlibs/utils'
import {Card} from 'sharecomponents/src/components/SigninLayout'
import {Icon, Loading} from '@startlibs/components'
import {lighten} from 'polished'


export const SignInCard = styled(Card)`
  max-width: 35rem;
`
export const WelcomeMessage = styled.h1 `
  color: ${getColor('main')};
  font-size: 18px;
  max-width: 33rem;
  margin: 0 auto 2rem;
  line-height: 130%;
  text-align: center;
`
export const SignInInstruction = styled.div `
  font-size: 15px;
  color: rgba(0,0,0,0.5);
  margin-bottom: 2rem;
  text-align: center;
`
export const FeeDetails = styled.div `
  text-align: center;
  margin: 1.4rem auto -0.5rem;
  max-width: 23rem;
  color: rgba(0,0,0,0.4);
`


export const DropDownIcon = styled(Icon)`
`

export const TextButton = styled.a`
  text-decoration: none;
  color: ${getColor('main')};
  padding: 0.5rem 0.75rem;
  font-weight: 600;
  border-radius: 5px;
  user-select: none;
  position:relative;
  display: flex;
  align-items: center;
  justify-content: center;
  & ~ & {
    margin-left: 0.5rem;
  }
  :hover {
    background-color: rgba(0, 139, 210, 0.1);
  }
  :active {
    background-color: rgba(0, 139, 210, 0.15);
  }
  ${props => props.focused && css`
    background-color: rgba(0, 139, 210, 0.1);
    color: ${getColor('main')};
  `}
  ${props => props.isLoading && css`
    background-color: rgba(0, 139, 210, 0.1);
    color: ${getColor('main')};
    pointer-events: none;
  `}
  ${props => props.started && css`
    background-color: ${props => lighten(0.4, props.theme.colors.success)};
    color: ${getColor('success')};
    pointer-events: none;
  `}
  ${props => props.disabled && css`
    color: ${getColor('gray90')};
    pointer-events: none;
  `}
  ${Loading} {
    display: inline-block;
    margin-right: 4px;
    vertical-align: sub;
  }
  ${Icon} {
    font-size: 18px;
    margin-right: 4px;
    vertical-align: bottom;
  }
  ${DropDownIcon} {
    font-size: 10px;
    margin-left: 0.25rem;
    margin-right: 0;
    transform: translateY(1px);
    vertical-align: baseline;
  }
`
