import { Loading } from '@startlibs/components';
import { isMobile, getColor, media } from '@startlibs/utils';
import React, { useRef, useState } from 'react';
import styled, {css} from 'styled-components'

import {PatientRow} from './PatientRow'
import { TextButton } from '../../RecordList';

const PatientTable = styled.div`
  width: 100%;
  color: ${getColor('gray60')};
  background: white;
  border: 1px solid rgba(0,0,0,0.15);
  border-spacing: 0;
  border-radius: 5px;
  position: relative;
  ${props => props.isLoading && `
    pointer-events: none;
    ${PatientTableRow} {
      border-color: transparent;
      > * {
        opacity: 0.1;
        transition: 0.5s ease;
      }
    }
  `}
  ${media.max(799)`
    border: 0;
  `}
`

export const PatientTableRow = styled.div`
  min-height: 4rem;
  position: relative;
  border-bottom: 1px solid rgb(225,225,225);
  display: flex;
  background: white;
  ${media.min(800)`
    :nth-child(even) {
      background: #f0f6f8;
    }
    :last-child {
      border: none;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  `}
  ${media.max(799)`
    display: block;
    margin-bottom: 0.75rem;
    background-color: white;
    border-radius: 5px;
    border: 1px solid rgba(0,0,0,0.15);
    padding: 1.5rem;
    position: relative;
  `}
`
export const PatientTableHead = styled(PatientTableRow)`
  align-items: center;
  font-weight: 600;
  background: white;
  top: 5.5rem;
  position: sticky;
  z-index: 15;
  border-top-left-radius:5px;
  border-top-right-radius:5px;
  border-color: rgba(0,0,0,0.15) !important;
  @media all and (-ms-high-contrast:none) /* IE fixes */
   {
     position: static;
     padding-top: .5rem;
     *::-ms-backgrop, & { position: static; padding-top: .5rem; }
   }
  ${media.max(799)`
    display: none;
  `}
`
export const PatientTableColumn = styled.div`
  flex-basis: 0;
  padding: 0.75rem 0.75rem;
  :first-child {
    padding-left: 1rem;
  }
  :last-child {
    padding-right: 1rem;
  }
  &.id {
      flex-grow: 0;
      min-width: 110px;
  }
  &.patient {
    flex-grow: 1;
    max-width: 250px;
  }
  &.study-desc {
    flex-grow: 1;
    max-width: 250px;
  }
  &.upload-date {
    min-width: 110px;
  }
  &.actions {
    flex-grow: 1;
    min-width: 210px;
    text-align: right;
    display: flex;
    justify-content: flex-end;
  }
  ${media.max(799)`
    padding: 0;
    :before {
      font-weight: 600;
      display: block;
    }
    &.id {
      position: absolute;
      top: 1.5rem;
      right: 1.5rem;
      border-radius: 5px;
      background-color: rgba(0,0,0,0.05);
      padding: .25rem .5rem;
      text-align: right;
      min-width: auto;
      max-width: 110px;
      :before {
        content: "Access code: ";
      }
    }
    &.patient {
      margin-bottom: 0.5rem;
      font-size: 16px;
      padding-right: 110px;
      :before {
        content: "Patient name: ";
        font-size: 13px;
      }
      .dob {
        font-size: 13px;
        margin-top: .5rem;
      }
    }
    &.study-desc {
      margin-bottom: 0.5rem;
      :before {
        content: "Exam details: ";
      }
    }
    &.upload-date {
      :before {
        content: "Upload date: ";
      }
    }
    &.actions {
      display: flex;
      margin: 1.25rem -1.5rem -1.5rem;
      padding: 0.25rem;
      background: rgba(41, 122, 163, 0.07);
      > * {
        flex-grow: 1;
        text-align: center;
        flex-shrink: 0;
        margin: 0;
      }
    }
  `}
  ${media.max(520)`
    &.id {
      position: static;
      max-width: 100%;
      text-align: left;
      padding: 0 0 .25rem;
      border-bottom: 1px solid ${getColor('gray210')};
      margin-bottom: 1rem;
      background: transparent;
      border-radius: 0;
      &:before {
        display: inline;
      }
    }
    &.patient {
      padding-right: 0;
    }
  `}
`

const LoadingTableContent = styled.div`
  position: absolute;
  top: 4rem;
  left: 0;
  right: 0;
  height: 100%;
  height: calc(100% - 4rem);
  max-height: 80vh;
  max-height: calc(80vh - 4rem);
  ${props => !props.isMobile && css`
    ${media.desktop`
      max-width: calc(100vw - 5rem - 15px);
      left: 2.5rem;
    `}
    ${media.mobile`
      max-width: calc(100vw - 15px);
      left: 0;
    `}
  `}
`

const EmptyStudyList = styled.div`
  background: rgb(240,240,240);
  min-height: 8rem;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 14px;
  max-width: calc(100vw);
  position: sticky;
  left: 0
`

export const Table = ({setParams, refreshList, providerInfo, jwt, rows, isLoading}) => {

  const [patients] = useState(rows)
  const tableRef = useRef()


  return <PatientTable ref={tableRef} isLoading={isLoading} isMobile={isMobile()}>
    <PatientTableHead isMobile={isMobile}>
      <PatientTableColumn className="id">Access code</PatientTableColumn>
      <PatientTableColumn className="patient">Patient</PatientTableColumn>
      <PatientTableColumn className="study-desc">Exam details</PatientTableColumn>
      <PatientTableColumn className="upload-date">Upload date</PatientTableColumn>
      <PatientTableColumn className="actions"></PatientTableColumn>
    </PatientTableHead>
    {patients.length ?
      patients.map(patient =>
        <PatientRow setParams={setParams} refreshList={refreshList} isLoading={isLoading}
                    providerInfo={providerInfo}
                  key={patient.id}
                  jwt={jwt}
                  patient={patient} isMobile={isMobile()}
        />)
      :
      <EmptyStudyList>{!isLoading && 'No records found'}</EmptyStudyList>
    }
    {isLoading &&
    <LoadingTableContent isMobile={isMobile()}><Loading absolute size={40} borderWidth={6}/></LoadingTableContent>}
  </PatientTable>
}
