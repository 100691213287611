import React from 'react'
import {Button, ConfirmDialog, DefaultConfirmDialog} from '@startlibs/components'

export default {
  dialogs: {
    'submit-draft': ({submit, cancel, isLoading, discard}) =>
      <ConfirmDialog
        title="Cancel payment?"
        closeDialog={cancel}
        onConfirm={cancel}
        footer={<>
          <Button onClick={discard}>Cancel payment</Button>
          <Button highlight onClick={cancel}>Continue payment</Button>
        </>}
      />,
    'discard-draft': ({submit, cancel, isLoading, discard}) => <DefaultConfirmDialog
      onCancel={cancel}
      onConfirm={discard}
      isLoading={isLoading}
      onSaveInstead={submit}
    />
  },
  confirmDialogLabels: {
    title: 'Your changes will not be saved',
    content: 'The changes made in this screen were not saved. Would you like to quit anyway?',
    confirm: 'Save Changes',
    discard: 'Discard Changes',
    cancel: 'Cancel'
  },
  labels: {
    EditableBox: {
      save: 'Save',
      saveNew: 'Save',
      cancel: 'Cancel'
    }
  },
  FormUploadingImageError: 'The image is being uploaded, please wait a monent.',
  ErrorDialog: {
    title: 'This content could not be loaded',
    content: <React.Fragment>Please refresh the page and try again. <br/>If the problem persists, contact
      us.</React.Fragment>,
    close: 'Close'
  },
  dateMessages: (type, n, capitalized) => capitalized !== false ? capitalize(type === 'month' ? MONTHS[n] : WEEKDAYS[n]) : type === 'month' ? MONTHS[n] : WEEKDAYS[n],
  fetchRoutes: []
}

const capitalize = (value) => value.slice(0,1).toUpperCase() + value.slice(1).toLowerCase()

const MONTHS = {
  '0': 'january',
  '1': 'february',
  '2': 'march',
  '3': 'april',
  '4': 'may',
  '5': 'june',
  '6': 'july',
  '7': 'august',
  '8': 'september',
  '9': 'october',
  '10': 'november',
  '11': 'december',
}

const WEEKDAYS = {
  '0': 'sunday',
  '1': `monday`,
  '2': `tuesday`,
  '3': `wednesday`,
  '4': `thursday`,
  '5': `friday`,
  '6': 'saturday',
}
