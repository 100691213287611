import {Button} from '@startlibs/components'
import { ConfirmDialog, SimpleCheckbox } from '@startlibs/form';
import { getColor, safeNewLineToBr } from '@startlibs/utils';
import React from 'react'
import styled from 'styled-components';

import {buildValidation} from '../../utils/validation'
import {jwtPostFetcher} from '../../utils/authFetch'

export const TermsContainer = styled.div`
  background: white;
  border: 1px solid ${getColor('gray210')};
  border-radius: 5px;
  display: block;
  margin-bottom: 2rem;
  overflow: hidden;
  position: relative;
  font-size: 12px;
  min-height: 10rem;
  .termsContent {
    max-height: 25rem;
    overflow: auto;
    padding: 1.5rem;
  }
  p {
    font-size: 12px;
  }
  h4 {
    margin-top: 1rem;
  }
  ul, ol {
    padding-left: 1.5rem;
    margin-bottom: .5rem;
    font-size: 12px;
  }
  ul {
    list-style-type: disc;
  }
  ol {
    ol {
      list-style-type: lower-alpha;
    }
  }
  li ~ li {
    margin-top: .25rem;
  }
`

export const AcceptTermsDialog = ({closeDialog,onSuccess,providerInfo,jwt}) => {
  return <ConfirmDialog
    title="Agreement to Terms and Conditions"
    css="max-width: 54rem;"
    closeDialog={onSuccess ? undefined : closeDialog}
    keepOnSuccess={!!onSuccess}
    confirmChanges={false}
    isLoading={false}
    cancelButton={onSuccess ? <Button onClick={closeDialog}>Disagree</Button> : false}
    action={onSuccess && (() => jwtPostFetcher(jwt)("/api/v2/acceptTerms",{origin:"WEB"}))}
    onSuccess={() => {onSuccess(); closeDialog()}}
    preValidation={onSuccess && buildValidation({
      acceptTerms:(v) => !v && 'You must confirm your agreement by checking the box to proceed.'
    })}
    confirm={onSuccess ? <Button highlight>Confirm agreement</Button> : <Button onClick={closeDialog}>Close</Button>}
  >
    <TermsContainer css="margin-bottom: 0;">
      <div className="termsContent">
        <div dangerouslySetInnerHTML={{__html:providerInfo.providerTerms}} />
      </div>
    </TermsContainer>
    {onSuccess &&
      <SimpleCheckbox
        framedBoxes
        path='acceptTerms'
        label='I confirm I have read and agree to the terms above.'
        css="margin-top: 1rem; margin-bottom: 0;"
        />
    }
  </ConfirmDialog>
}
