import { Icon } from '@startlibs/components';
import { getColor, isMobile } from '@startlibs/utils';
import {useToggle} from '@startlibs/core'
import React from 'react'
import styled from 'styled-components';

import {PatientShareDialog} from '../PatientShareDialog'
import {PatientTableColumn} from './Table';
import {PatientTableRow} from './Table';
import {TextButton} from '../../../components/StyledComponents'

const STATUS_TO_COLOR = {
  'normal': 'success',
  'stat': 'alert',
  'time sensitive': 'warning',
}

const CaseStatus = styled.span`
  color: ${props => getColor(STATUS_TO_COLOR[(props.status || "").toLowerCase()] || "gray120")(props)};
  font-weight: 600;
`



export const PatientRow = ({activeToolsAmount, setParams, patient, providerInfo, jwt}) => {
  // const uploadDate = strToDate(study.study.uploadDate)
  // const formattedUploadDate = isNaN(uploadDate.getTime()) ? "" : formatDate(uploadDate, DATE_FORMAT())
  //
  const shareDialog = useToggle()
  return <PatientTableRow isMobile={isMobile()}>
    <PatientTableColumn className="id">{patient.accessCode}</PatientTableColumn>
    <PatientTableColumn className="patient">
      <div className="patient-name"><strong>{patient.name}</strong></div>
      <div className="nowrap dob"><strong>DOB:</strong> {patient.dob}</div>
    </PatientTableColumn>
    <PatientTableColumn className="study-desc">{patient.studyDescription || "No description"} - {patient.created}</PatientTableColumn>
    <PatientTableColumn className="upload-date">{patient.uploaded}</PatientTableColumn>
    <PatientTableColumn className="actions">
      <TextButton onClick={shareDialog.open}><Icon icon="email"/>Send email</TextButton>
      <TextButton onClick={shareDialog.willOpenWith("link")}><Icon icon="link"/>Get link</TextButton>
    </PatientTableColumn>
    {shareDialog.isOpen && <PatientShareDialog providerInfo={providerInfo} closeDialog={shareDialog.close} shareableLinkIsOpen={shareDialog.isOpen==="link"} patient={patient} jwt={jwt} />}
  </PatientTableRow>
}
